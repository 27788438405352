import { Checkbox } from '@consigli/facade';
import { useSelectedCard } from '@consigli/hooks';

import { ViewerMode } from '@/contexts/use-viewer-context';
import { useViewer } from '@/hooks/use-viewer';
import { convertBlobToDocument } from '@/util/document-converters';
import { FolderDocumentsType } from '@/util/types';

import { FoldersCard } from './folders-card';

interface FoldersCardListProps {
  pageRecords: FolderDocumentsType[];
  filterItems: (items: FolderDocumentsType[]) => FolderDocumentsType[];
  handleCheckboxClick: (record: FolderDocumentsType) => void;
}

export const FoldersCardList = ({
  pageRecords,
  filterItems,
  handleCheckboxClick,
}: FoldersCardListProps) => {
  const { openDocumentViewer } = useViewer();
  const { selectedCard, setSelectedCard } = useSelectedCard('');
  return (
    <div className="px-4 h-[calc(100vh-20rem)] overflow-auto">
      {filterItems(pageRecords).map((file) => (
        <div key={file.id} className="flex items-center">
          <div className="mr-4">
            <Checkbox
              value={file.id}
              label=""
              id={file.id}
              checked={file.checked}
              onChange={() => handleCheckboxClick(file)}
            ></Checkbox>
          </div>
          <div className="flex-1">
            <FoldersCard
              file={file}
              selected={selectedCard === file.id}
              onClick={() => {
                setSelectedCard(file.id);
                openDocumentViewer(convertBlobToDocument(file), ViewerMode.DocumentPreview);
              }}
              handleCheckboxClick={handleCheckboxClick}
            ></FoldersCard>
          </div>
        </div>
      ))}
    </div>
  );
};
