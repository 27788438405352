import { ServiceName, ServicePermissionName } from '@consigli/types';
import { TFunction } from 'i18next';

export const translateServiceTypes = (t: TFunction<'translation', undefined>) => [
  {
    label: t('services.management-operations-maintenance'),
    value: ServicePermissionName.USE_MANAGE_OPERATE_SERVICE,
  },
  {
    label: t('services.offer'),
    value: ServicePermissionName.USE_OFFER_SERVICE,
  },
  {
    label: t('services.tender-documents'),
    value: ServicePermissionName.USE_TENDER_DOCUMENTS_SERVICE,
  },
  {
    label: t('services.due-diligence'),
    value: ServicePermissionName.USE_DUE_DILIGENCE_SERVICE,
  },
  {
    label: t('services.tenant-requirements'),
    value: ServicePermissionName.USE_TENANT_REQ_SERVICE,
  },
  {
    label: t('services.tenant-specification'),
    value: ServicePermissionName.USE_TENANT_SPEC_SERVICE,
  },
  {
    label: t('services.floor-plan'),
    value: ServicePermissionName.USE_FLOOR_PLAN_SERVICE,
  },
  {
    label: t('services.ceiling-plan'),
    value: ServicePermissionName.USE_CEILING_PLAN_SERVICE,
  },
  {
    label: t('services.plant-room'),
    value: ServicePermissionName.USE_PLANT_ROOM_SERVICE,
  },
  {
    label: t('services.space-analysis'),
    value: ServicePermissionName.USE_SPACE_ANALYSIS_SERVICE,
  },
  {
    label: t('services.structural'),
    value: ServicePermissionName.USE_STRUCTURAL_SERVICE,
  },
];

export const compareWithTenantServiceTypes = (t: TFunction<'translation', undefined>) => [
  {
    label: t('services.tender-documents'),
    value: ServiceName.TENDER_DOCUMENTS,
  },
  {
    label: t('services.tenant-specification'),
    value: ServiceName.TENANT_SPECIFICATION,
  },
];

export const compareWithOfferServiceTypes = (t: TFunction<'translation', undefined>) => [
  {
    label: t('services.tender-documents'),
    value: ServiceName.TENDER_DOCUMENTS,
  },
];
