import {
  useProjectId,
  useCreatePackageMutation,
  useStartPipelineMutation,
  useBlobUpload,
  useServiceName,
} from '@consigli/hooks';
import {
  CreatePackageRequest,
  Language,
  PipelineName,
  ServiceId,
  StartPipelineRequest,
} from '@consigli/types';
import limit from 'p-limit';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Route } from '@/routes';

export const useCreateInsightPackage = () => {
  const projectId = useProjectId();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const serviceName = useServiceName();
  const [createPackage] = useCreatePackageMutation();
  const [startPipeline] = useStartPipelineMutation();
  const { upload } = useBlobUpload();

  const [targetCounter, setTargetCounter] = useState(0);
  const [currentCounter, setCurrentCounter] = useState(0);

  const createDocumentPackage = useCallback(
    async (
      serviceId: ServiceId,
      packageName: string,
      language: Language,
      needClassification?: boolean,
    ) => {
      try {
        const payload: CreatePackageRequest = {
          projectId: projectId,
          serviceId: serviceId,
          name: packageName,
          language,
          needClassification,
        };
        const response = await createPackage(payload).unwrap();
        if (response) return response.id;
      } catch (error) {
        toast.error(t('package-creation.package-create-failed'));
      }
    },
    [createPackage, projectId, t],
  );

  const isUploading = useMemo(
    () => currentCounter !== targetCounter,
    [currentCounter, targetCounter],
  );

  const progress = useMemo(
    () => Math.floor((currentCounter / targetCounter) * 100 || 0),
    [currentCounter, targetCounter],
  );

  const uploadWithCounter = useCallback(
    async (file: File, documentPackageId: string) => {
      let blob;
      try {
        blob = await upload(file, documentPackageId);
      } finally {
        setCurrentCounter((counter) => counter + 1);
        return blob;
      }
    },
    [upload, setCurrentCounter],
  );

  const uploadFiles = useCallback(
    async (files: File[], documentPackageId: string) => {
      setCurrentCounter(0);
      setTargetCounter(files.length);

      const queue = limit(100);
      const input = files.map((file) => queue(uploadWithCounter, file, documentPackageId));

      await Promise.allSettled(input);
    },
    [setCurrentCounter, setTargetCounter, uploadWithCounter],
  );

  const comparePackages = useCallback(
    async (primaryPackageId: string, secondaryPackageId: string) => {
      const payload: StartPipelineRequest = {
        packageId: primaryPackageId,
        projectId: projectId,
        pipelineName: PipelineName.DEFAULT,
        secondaryPackageId: secondaryPackageId,
      };
      await startPipeline(payload).unwrap();
    },
    [projectId, startPipeline],
  );

  const startAnalysis = useCallback(
    async (packageId: string, pipelineName: PipelineName) => {
      await startPipeline({
        projectId,
        packageId,
        pipelineName,
      });

      navigate(
        `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${packageId}/${Route.DASHBOARD}`,
      );
    },
    [startPipeline, projectId, navigate, serviceName],
  );

  return {
    createDocumentPackage,
    uploadFiles,
    isUploading,
    progress,
    comparePackages,
    startAnalysis,
  };
};
