import { LoadingArea } from '@consigli/facade';
import { useReadUrl } from '@consigli/hooks';
import { Document, DocumentType } from '@consigli/types';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';

import { PDFViewer, getPDFSize } from '@/molecules/pdf-viewer/pdf-viewer';

interface SinglePdfViewProps {
  document: Document;
  isPreview: boolean;
}

export const SinglePdfViewer: FC<SinglePdfViewProps> = ({ document, isPreview }) => {
  const [pdfSize, setPDFSize] = useState<{ pdfWidth: number; pdfHeight: number }>();

  const { readUrl } = useReadUrl(document);

  useEffect(() => {
    if (readUrl === undefined) return;
    const previewUrl = readUrl ?? '';
    getPDFSize(previewUrl).then((size) => setPDFSize(size));
  }, [readUrl]);

  if (readUrl === undefined) {
    return <LoadingArea />;
  }
  // Document is a finding
  if (document.type === DocumentType.FINDING && readUrl) {
    return (
      <div className={clsx(isPreview ? 'w-11/12 mb-1' : 'w-5/6 mb-6')}>
        <div className="text-white text-md text-center pt-3 pl-5 truncate">{document.name}</div>
        <PDFViewer
          fileUrl={readUrl}
          documentName={document.name}
          findingCoordinates={document.findingCoordinates}
          pdfSize={pdfSize}
          searchTerm={document.content}
          searchFallbackPage={document.pageNumber ?? undefined}
          isPreview={isPreview}
        />
      </div>
    );
  }
  // Document is a Blob or ParsedData
  if (document.type === DocumentType.BLOB && readUrl) {
    return (
      <div className={clsx(isPreview ? 'w-11/12 mb-1' : 'w-5/6 mb-6')}>
        <div className="text-white text-md text-center pt-3 pl-5 truncate">{document.name}</div>
        <PDFViewer fileUrl={readUrl} documentName={document.name} isPreview={isPreview} />
      </div>
    );
  }
  // Document is a File
  if (document.type === DocumentType.FILE && readUrl) {
    return (
      <div className={clsx(isPreview ? 'w-11/12 mb-1' : 'w-5/6 mb-6')}>
        <div className="text-white text-md text-center pt-3 pl-5 truncate">{document.name}</div>
        <PDFViewer fileUrl={readUrl} documentName={document.name} isPreview={isPreview} />
      </div>
    );
  }
};
