import { BlobParsedStatus, } from '@consigli/types';
import { useCallback } from 'react';
import { useFileUpload } from './use-file-upload';
import { useProjectId } from './use-project-id';
import { useCreateBlobMutation } from '../api';
/**
 * Hook to upload a file and create a blob
 *
 * @returns an upload function
 */
export const useBlobUpload = () => {
    const projectId = useProjectId();
    const { upload: uploadFile } = useFileUpload();
    const [createBlob] = useCreateBlobMutation();
    const upload = useCallback(async (file, packageId, onProgress) => {
        const uploadedFile = await uploadFile(file, projectId, onProgress);
        const blobPayload = {
            documentPackage: packageId,
            name: file.name,
            parsedStatus: BlobParsedStatus.UPLOADED,
            originalFileId: uploadedFile.id,
        };
        return await createBlob({ projectId, packageId, data: blobPayload }).unwrap();
    }, [projectId, uploadFile, createBlob]);
    return {
        upload,
    };
};
