import { ProjectRole } from '@consigli/types';
import clsx from 'clsx';
import { FC } from 'react';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';

interface UserManagementProjectAccessCardProps {
  name: string;
  role: ProjectRole;
}

export const UserManagementProjectAccessCard: FC<UserManagementProjectAccessCardProps> = ({
  name,
  role,
}) => {
  const isProjectAdmin = role === ProjectRole.ADMIN;
  return (
    <div
      className={clsx(
        isProjectAdmin ? 'bg-night-light-3' : 'bg-day-light-2',
        isProjectAdmin ? 'text-night-neutral-dark' : 'text-day-neutral-dark',
        'px-2 py-1 m-1 text-xs rounded font-bold flex max-w-[8rem]',
      )}
    >
      {isProjectAdmin && (
        <IoShieldCheckmarkOutline
          size={16}
          title="ProjectAdminIcon"
          className="mr-1 flex-shrink-0"
        />
      )}
      <p className="max-w-48 truncate">{name}</p>
    </div>
  );
};
