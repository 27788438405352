import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { documentPackageSchema } from './document-package';
import { FoldersDocumentType } from './folders-document-type';
import { NS3451 } from './ns3451';
import { getPaginationResponseSchema } from './pagination';
import { projectSchema } from './project';
export var BlobUpdatedBy;
(function (BlobUpdatedBy) {
    BlobUpdatedBy["PROCESSOR"] = "PROCESSOR";
    BlobUpdatedBy["CONSIGLI"] = "CONSIGLI";
    BlobUpdatedBy["CUSTOMER"] = "CUSTOMER";
})(BlobUpdatedBy || (BlobUpdatedBy = {}));
export var BlobParsedStatus;
(function (BlobParsedStatus) {
    BlobParsedStatus["PENDING"] = "PENDING";
    BlobParsedStatus["UPLOADING"] = "UPLOADING";
    BlobParsedStatus["UPLOADED"] = "UPLOADED";
    BlobParsedStatus["UPLOAD_FAILED"] = "UPLOAD_FAILED";
    BlobParsedStatus["UNPARSED"] = "UNPARSED";
    BlobParsedStatus["PARSED"] = "PARSED";
    BlobParsedStatus["PARSING_FAILED"] = "PARSING_FAILED";
    BlobParsedStatus["DUPLICATE"] = "DUPLICATE";
})(BlobParsedStatus || (BlobParsedStatus = {}));
export const blobParsedStatusSchema = z.nativeEnum(BlobParsedStatus);
export const blobParsedDataSchema = z
    .object({
    remaining_occurrences: z.number().int().optional(),
    data: z
        .array(z.object({
        id: z.number().int(),
        content: z.string(),
    }))
        .optional(),
})
    .strict()
    .transform(mapToCamelCase);
export const blobSchema = z
    .object({
    id: z.string().uuid(),
    project: projectSchema.innerType().shape.id,
    document_package: documentPackageSchema.innerType().shape.id,
    md5: z.string().nullable(),
    name: z.string(),
    name_suggestion: z.string().nullable(),
    extension: z.string(),
    parsed_status: blobParsedStatusSchema,
    blob_url: z.string(),
    viewer_url: z.string().nullable(),
    parsed_data: blobParsedDataSchema,
    original_file_id: z.string().uuid(),
    converted_pdf_file_id: z.string().uuid().nullable(),
    ocr_pdf_file_id: z.string().uuid().nullable(),
    ns3451: z.array(z.nativeEnum(NS3451)),
    document_type: z.array(z.nativeEnum(FoldersDocumentType)),
    external_famac_file_id: z.string().nullable(),
})
    .strict()
    .transform(mapToCamelCase);
export const getBlobsResponseSchema = getPaginationResponseSchema(blobSchema);
export const getBlobsRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    search_name: z.string().nullish(),
    search_parsed_data: z.string().nullish(),
    status: z.array(z.nativeEnum(BlobParsedStatus)).nullish(),
    suggestion: z.boolean().nullish(),
    standard: z.string().nullish(),
    document_type: z.array(z.nativeEnum(FoldersDocumentType)).nullish(),
    blob_ids: z.array(z.string().uuid()).nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const createBlobSchema = blobSchema
    .innerType()
    .pick({
    document_package: true, // TODO: Remove once Blob is nested under packages in API
    name: true,
    parsed_status: true,
    original_file_id: true,
})
    .required()
    .strict()
    .transform(mapToCamelCase);
export const getBlobReadUrlResponseSchema = z
    .object({
    read_url: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const getBlobUploadUrlResponseSchema = z
    .object({
    upload_url: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const updateBatchBlobsResponseSchema = z.array(blobSchema).transform(mapToCamelCase);
export const blobFilterCountSchema = z
    .object({
    parsed: z.number().int(),
    unparsed: z.number().int(),
    duplicate: z.number().int(),
    suggestion: z.number().int(),
})
    .strict()
    .transform(mapToCamelCase);
export const getBlobFilterCountRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    search_parsed_data: z.string().nullish(),
    blob_ids: z.array(z.string().uuid()).nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getBlobNSCountRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    document_type: z.array(z.nativeEnum(FoldersDocumentType)),
    search_name: z.string().nullable(),
    search_parsed_data: z.string().nullable(),
})
    .strict()
    .transform(mapToCamelCase);
export const getBlobNSCountResponseSchema = z.record(z.number());
export const getBlobUniqueDocumentTypesRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    standard: z.string().nullable(),
    search_name: z.string().nullable(),
    search_parsed_data: z.string().nullable(),
    documentType: z.array(z.nativeEnum(FoldersDocumentType)).nullable(),
})
    .strict()
    .transform(mapToCamelCase);
export const getBlobUniqueDocumentTypesResponseSchema = z.array(z.nativeEnum(FoldersDocumentType));
export const blobHistorySchema = z
    .object({
    blob_id: z.string().uuid(),
    blob_name: z.string(),
    ns3451: z.object({
        from: z.object({
            value: z.array(z.nativeEnum(NS3451)),
            created_by: z.nativeEnum(BlobUpdatedBy).nullable(),
        }),
        to: z.object({
            value: z.array(z.nativeEnum(NS3451)),
            created_by: z.nativeEnum(BlobUpdatedBy).nullable(),
        }),
    }),
    document_type: z.object({
        from: z.object({
            value: z.array(z.nativeEnum(FoldersDocumentType)),
            created_by: z.nativeEnum(BlobUpdatedBy).nullable(),
        }),
        to: z.object({
            value: z.array(z.nativeEnum(FoldersDocumentType)),
            created_by: z.nativeEnum(BlobUpdatedBy).nullable(),
        }),
    }),
})
    .strict()
    .transform(mapToCamelCase);
export const getBlobHistoryResponseSchema = getPaginationResponseSchema(blobHistorySchema);
export const getBlobHistoryRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    page: z.union([z.number().int(), z.literal('all')]),
})
    .strict()
    .transform(mapToCamelCase);
export const blobNameListSchema = z.array(z
    .object({
    id: z.string().uuid(),
    name: z.string(),
})
    .strict()
    .transform(mapToCamelCase));
export const getBlobNameListRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    search_parsed_data: z.string().nullish(),
    status: z.array(z.nativeEnum(BlobParsedStatus)).nullish(),
})
    .strict()
    .transform(mapToCamelCase);
