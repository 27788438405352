import { type Finding, type Blob, File } from '@consigli/types';
import { Document, DocumentType } from '@consigli/types';
import { type TFunction } from 'i18next';
import { useCallback } from 'react';

export const useConvertFindingToDocument = (t: TFunction) => {
  return useCallback(
    (finding: Finding): Document => {
      const findingCoordinates: Document['findingCoordinates'] =
        finding.x0 != null &&
        finding.x1 != null &&
        finding.y0 != null &&
        finding.y1 != null &&
        finding.pageNumber != null
          ? {
              x0: finding.x0,
              x1: finding.x1,
              y0: finding.y0,
              y1: finding.y1,
              pageIndex: finding.pageNumber,
            }
          : undefined;
      const refFindingCoordinates: Document['findingCoordinates'] =
        finding.refX0 != null &&
        finding.refX1 != null &&
        finding.refY0 != null &&
        finding.refY1 != null &&
        finding.refPageNumber != null
          ? {
              x0: finding.refX0,
              x1: finding.refX1,
              y0: finding.refY0,
              y1: finding.refY1,
              pageIndex: finding.refPageNumber,
            }
          : undefined;
      return {
        id: finding.blobId!,
        name: finding.fileName ?? t('riskassessment.project-related-finding'),
        documentPackageId: finding.documentPackageId,
        extension: finding.fileName
          ? '.' + finding.fileName.toLocaleLowerCase().split('.').pop()
          : '',
        pageNumber: finding.pageNumber!,
        content: finding.content ?? t('riskassessment.no-document-reference'),
        type: DocumentType.FINDING,

        refBlobId: finding.refBlobId,
        refDocumentPackageId: finding.refDocumentPackageId,
        refContent: finding.refContent,
        refFileName: finding.refFileName,
        refPageNumber: finding.refPageNumber,
        typeOfConflict: finding.typeOfConflict,
        typeOfConflictName: finding.typeOfConflictName,
        messageTemplate: finding.messageTemplate,
        messageContext: finding.messageContext,
        messageContextEn: finding.messageContextEn,
        findingId: finding.id,
        projectId: finding.projectId,
        findingCoordinates: findingCoordinates,
        refFindingCoordinates: refFindingCoordinates,
      };
    },
    [t],
  );
};

export const convertBlobToDocument = (blob: Blob): Document => {
  return {
    id: blob.id,
    name: blob.name,
    nameSuggestion: blob.nameSuggestion || undefined,
    documentPackageId: blob.documentPackage,
    extension: blob.extension,
    parsedData: blob.parsedData,
    type: DocumentType.BLOB,
    blobUrl: blob.blobUrl,
    ns3451: blob.ns3451,
  };
};

export const convertFileToDocument = (file: File): Document => {
  return {
    id: file.id,
    name: file.originalFilename,
    extension: file.filenameExtension,
    projectId: file.projectId,
    type: DocumentType.FILE,
  };
};
