import { DocumentsType, DocumentType } from '@consigli/types';

import { convertBlobToDocument } from './document-converters';
import { FolderDocumentsType } from './types';

// this enables reusing the download functionality although the type is different (blob vs document)
export function convertFolderDocumentsTypeToDocumentsType(
  folderDocument: FolderDocumentsType,
): DocumentsType {
  const { checked, ...blob } = folderDocument;
  const document = convertBlobToDocument(blob);
  return {
    checked: checked,
    ...document,
    ns3451: document.ns3451 ?? [],
    blobUrl: document.blobUrl ?? '',
    documentPackageId: document.documentPackageId ?? '',
    parsedData: {},
    type: DocumentType.BLOB,
  };
}
