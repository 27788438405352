import { LoadingArea } from '@consigli/facade';
import {
  useProjectId,
  useStructuralId,
  useStructuralResults,
  useLazyGetStructuralEngineeringResultsQuery,
  useStructuralResultType,
} from '@consigli/hooks';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/components/layouts/workspace-layout/workspace-layout';
import { WaitingForAnalysis } from '@/modules/pages/dashboard/waiting-for-analysis';
import { Documents } from '@/organisms/document-list/documents';
import { convertFileToDocument } from '@/util/document-converters';

export const StructuralResultFileList: FC = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const structuralId = useStructuralId();
  const structuralResultType = useStructuralResultType();

  const [page, setPage] = useState(1);

  const { results, totalCount, isLoading, isFetching } = useStructuralResults({
    projectId,
    structuralId,
    page: page,
    type: structuralResultType,
  });

  const [getAllStructuralResults] = useLazyGetStructuralEngineeringResultsQuery();

  const downloadAllFiles = useCallback(async () => {
    const structuralFilesResponse = await getAllStructuralResults({
      projectId,
      structuralId,
      page: 'all',
    }).unwrap();
    return structuralFilesResponse['results'].map((result) => convertFileToDocument(result.file));
  }, [getAllStructuralResults, projectId, structuralId]);

  return (
    <>
      <Breadcrumb label={t('structural-tabs.results')} />
      <WorkspaceLayout>
        {isLoading ? (
          <LoadingArea title={t('document-list.loading')} />
        ) : results.length === 0 ? (
          <WaitingForAnalysis />
        ) : (
          <Documents
            files={results.map((result) => convertFileToDocument(result.file))}
            headerTitle={t('document-list.results')}
            paginatedCount={totalCount}
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            isFetching={isFetching}
            downloadAllFiles={downloadAllFiles}
            packageId={structuralId}
          />
        )}
      </WorkspaceLayout>
    </>
  );
};
