import { z } from 'zod';
export var Language;
(function (Language) {
    Language["NB"] = "NB";
    Language["NN"] = "NN";
    Language["EN"] = "EN";
    Language["VI"] = "VI";
    Language["JA"] = "JA";
})(Language || (Language = {}));
export const languageSchema = z.nativeEnum(Language);
export var Country;
(function (Country) {
    Country["NORWAY"] = "NORWAY";
    Country["SWEDEN"] = "SWEDEN";
    Country["DENMARK"] = "DENMARK";
    Country["ICELAND"] = "ICELAND";
    Country["FINLAND"] = "FINLAND";
    Country["JAPAN"] = "JAPAN";
    Country["VIETNAM"] = "VIETNAM";
    Country["FRANCE"] = "FRANCE";
    Country["NETHERLANDS"] = "NETHERLANDS";
    Country["UNITED_KINGDOM"] = "UNITED_KINGDOM";
    Country["UNITED_STATES"] = "UNITED_STATES";
    Country["CANADA"] = "CANADA";
    Country["HUNGARY"] = "HUNGARY";
    Country["GERMANY"] = "GERMANY";
})(Country || (Country = {}));
export const countrySchema = z.nativeEnum(Country);
