import { Checkbox, Position, Search } from '@consigli/facade';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useFileTreeContext } from '@/contexts/use-file-tree-context';

interface SearchProps {
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DocumentsSearch = (props: SearchProps) => {
  const { t } = useTranslation();
  const { handleSearch } = props;
  const { setSearchByFilename, searchByFilename, searchText } = useFileTreeContext();

  const handleCheckboxClick = useCallback(() => {
    setSearchByFilename((prev) => !prev);
    handleSearch({ target: { value: searchText } } as React.ChangeEvent<HTMLInputElement>);
  }, [handleSearch, searchText, setSearchByFilename]);

  return (
    <div className="flex flex-col md:flex-row items-center">
      <Search
        text={searchText}
        onChange={handleSearch}
        placeholder={t('riskassessment.search_content')}
        className="w-full"
      />
      <Checkbox
        onChange={handleCheckboxClick}
        checked={searchByFilename}
        label={t('document-list.search-only-filename')}
        id="search-filename-only-selected"
        labelPosition={Position.LEFT}
      />
    </div>
  );
};
