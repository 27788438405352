import {
  Button,
  OptionType,
  Spinner,
  isOptionType,
  MessagePanel,
  MessagePanelTypes,
  Popup,
} from '@consigli/facade';
import { useMyUser, useProjects, useInviteUserMutation, useCurrentLanguage } from '@consigli/hooks';
import { ProjectRole, OrganizationRole, SendInviteRequest } from '@consigli/types';
import { useCallback, useState } from 'react';
import { useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MultiValue, SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import { Route } from '@/routes';
import { InviteType } from '@/util/types';

import { ConfirmProjectRemovalPopup } from './confirm-project-removal-popup';
import { InviteFields } from './invite-fields';
import { InviteProjectList } from './invite-project-list';
import { OrgAdmin } from './org-admin';
import { OrgUser } from './org-user';
import { ProjectUser } from './project-user';

export const InviteUserForm = () => {
  const { user } = useMyUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm<FieldValues>({
    defaultValues: {
      canCreateProjects: false,
      isProjectAdmin: false,
      permissions: [],
      recipient: '',
    },
  });
  const organizationRole = watch('organizationRole');
  const canCreateProjects = watch('canCreateProjects');
  const projectId = watch('projectId');
  const permissions = watch('permissions');
  const [showProjectPopup, setShowProjectPopup] = useState(false);
  const { projects: availableProjects } = useProjects({ page: 'all' });
  const [inviteUsers] = useInviteUserMutation();
  const [showButtons, setShowButtons] = useState(false);
  const [showMessagePanel, setShowMessagePanel] = useState(true);
  const [selectedProjectList, setSelectedProjectList] = useState<InviteType[]>();
  const [selectedProject, setSelectedProject] = useState<OptionType | undefined>(undefined);
  const [isInviting, setIsInviting] = useState(false);
  const currentLanguage = useCurrentLanguage();
  const isMultiValue = useCallback(
    (
      option: SingleValue<OptionType> | MultiValue<OptionType>,
    ): option is MultiValue<OptionType> => {
      return Array.isArray(option);
    },
    [],
  );
  const handleProjectSelect = useCallback(
    (option: SingleValue<OptionType> | MultiValue<OptionType>) => {
      if (isOptionType(option) && option != null) {
        const selectedOption = isMultiValue(option)
          ? (option as MultiValue<OptionType>)[0]
          : (option as SingleValue<OptionType>);
        if (selectedOption !== null) {
          setSelectedProject(selectedOption);
          setValue('projectId', selectedOption.value);
        }
      }
    },
    [isMultiValue, setValue],
  );
  const clearProject = useCallback(() => {
    setShowProjectPopup(false);
    setSelectedProject({ label: '', value: '' });
    setValue('permissions', '');
    setValue('projectId', '');
    setValue('projectAdminSelected', false);
  }, [setValue]);
  const isOrgAdmin = useCallback((role: OrganizationRole) => role === OrganizationRole.ADMIN, []);
  const isOrgUser = useCallback(
    (role: ProjectRole) => role === ProjectRole.USER && canCreateProjects,
    [canCreateProjects],
  );
  const isProjectAccess = useCallback(
    (role: ProjectRole) =>
      role === ProjectRole.USER && !canCreateProjects && selectedProjectList?.length,
    [canCreateProjects, selectedProjectList?.length],
  );
  const handleProjectAccess = useCallback(() => {
    const projectAccess = {
      id: projectId,
      role: getValues('projectAdminSelected') ? ProjectRole.ADMIN : ProjectRole.USER,
      permissions: [...permissions],
    };
    setSelectedProjectList((prevState) => {
      const exists =
        Array.isArray(prevState) && prevState.find((project) => project.id === projectId);
      if (!exists) {
        const result = availableProjects.find(({ id }) => id === projectId);
        return [
          ...(prevState || []),
          {
            id: projectAccess.id,
            role: projectAccess.role,
            permissions: projectAccess.permissions,
            name: result?.name,
          },
        ];
      }
      return prevState?.map((project) => {
        if (project.id === projectId) {
          return {
            ...project,
            id: projectAccess.id,
            role: projectAccess.role,
            permissions: projectAccess.permissions,
          };
        }
        return project;
      });
    });
    setShowProjectPopup(false);
    setValue('projectId', '');
    setValue('permissions', '');
    setSelectedProject(undefined);
    setValue('projectAdminSelected', '');
  }, [availableProjects, getValues, permissions, projectId, setValue]);
  const inviteUsersApi = useCallback(
    async ({ recipient, organization, projects, language }: SendInviteRequest) => {
      try {
        setIsInviting(true);
        await inviteUsers({
          recipient,
          organization,
          projects,
          language,
        }).unwrap();
        toast.info(t('invite-user.invite-success'));
      } catch (error) {
        toast.error(t('invite-user.invite-failed'));
      } finally {
        setIsInviting(false);
        navigate(`/${Route.USERS}`);
      }
    },
    [inviteUsers, t, navigate],
  );

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    async ({ organizationRole, recipient }) => {
      const commonApiParams = {
        recipient,
        language: currentLanguage,
      };
      if (isOrgAdmin(organizationRole) || isOrgUser(organizationRole)) {
        const role = isOrgAdmin(organizationRole) ? OrganizationRole.ADMIN : OrganizationRole.USER;
        const id = user.organizationId!;
        inviteUsersApi({
          ...commonApiParams,
          organization: { role, id },
          projects: isOrgUser(organizationRole)
            ? selectedProjectList?.map(({ id, permissions, role }) => ({ id, permissions, role }))
            : [],
        });
      } else if (isProjectAccess(organizationRole)) {
        inviteUsersApi({
          ...commonApiParams,
          projects: selectedProjectList?.map(({ id, permissions, role }) => ({
            id,
            permissions,
            role,
          })),
        });
      }
    },
    [
      inviteUsersApi,
      isOrgUser,
      isProjectAccess,
      selectedProjectList,
      user.organizationId,
      isOrgAdmin,
      currentLanguage,
    ],
  );
  const removeProject = useCallback(() => {
    setShowButtons(false);
    setSelectedProjectList((previous) =>
      previous?.filter((project) => project.id !== selectedProject?.value),
    );
    setValue('projectId', '');
    setSelectedProject(undefined);
  }, [selectedProject, setValue]);

  const disabledSendInvite = useMemo(() => {
    if (
      !organizationRole ||
      user.isSuperuser ||
      (organizationRole === ProjectRole.USER && !canCreateProjects && !selectedProjectList?.length)
    ) {
      return true;
    } else {
      return false;
    }
  }, [organizationRole, canCreateProjects, selectedProjectList, user.isSuperuser]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {user.isSuperuser && showMessagePanel && (
        <div className="mb-6">
          <MessagePanel type={MessagePanelTypes.WARNING} onClose={() => setShowMessagePanel(false)}>
            {t('invite-user.dont-invite-as-superuser')}
          </MessagePanel>
        </div>
      )}
      <h1 className="text-center text-3xl font-semibold">{t('invite-user.new-user')}</h1>
      <div className="flex flex-col">
        <InviteFields
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          watch={watch}
        />
        {organizationRole === ProjectRole.USER && (
          <OrgUser control={control} setShowProjectPopup={setShowProjectPopup} />
        )}
        {organizationRole === ProjectRole.ADMIN && <OrgAdmin />}
        {organizationRole !== ProjectRole.ADMIN && (
          <InviteProjectList
            selectedProjectList={selectedProjectList}
            setValue={setValue}
            setSelectedProject={setSelectedProject}
            setShowButtons={setShowButtons}
            showProjectPopup={showProjectPopup}
            setShowProjectPopup={setShowProjectPopup}
          />
        )}
        <ConfirmProjectRemovalPopup
          showButtons={showButtons}
          setShowButtons={setShowButtons}
          removeProject={removeProject}
        />
        {isInviting ? (
          <div className="w-full flex justify-center items-center">
            <Spinner size="medium" />
          </div>
        ) : (
          <Button rounded primary disabled={disabledSendInvite} type="submit">
            {t('invite-user.send-invite')}
          </Button>
        )}
        {showProjectPopup && (
          <Popup>
            <ProjectUser
              control={control}
              register={register}
              errors={errors}
              selectedProject={selectedProject}
              clearProject={clearProject}
              watch={watch}
              getValues={getValues}
              handleProjectAccess={handleProjectAccess}
              onSelect={handleProjectSelect}
              selectedProjectList={selectedProjectList}
            />
          </Popup>
        )}
      </div>
    </form>
  );
};
