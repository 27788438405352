import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchMessageTemplates } from '@/locales/message-templates';
import { DropdownElement } from '@/util/interfaces';

import { PageHeaderDropdown } from './page-header-dropdown';

export const TranslationMenuButton: FC = () => {
  const { t, i18n } = useTranslation();

  const translationOptions: DropdownElement[] = [
    {
      text: t('language.nb'),
      onClick: async () => {
        await fetchMessageTemplates('nb', 'translation');
        i18n.changeLanguage('nb');
      },
      className: i18n.language === 'nb' ? 'bg-day-light-2' : '',
    },
    {
      text: t('language.nn'),
      onClick: async () => {
        await fetchMessageTemplates('nn', 'translation');
        i18n.changeLanguage('nn');
      },
      className: i18n.language === 'nn' ? 'bg-day-light-2' : '',
    },
    {
      text: t('language.en'),
      onClick: async () => {
        await fetchMessageTemplates('en', 'translation');
        i18n.changeLanguage('en');
      },
      className: i18n.language === 'en' ? 'bg-day-light-2' : '',
    },
    {
      text: t('language.ja'),
      onClick: async () => {
        await fetchMessageTemplates('ja', 'translation');
        i18n.changeLanguage('ja');
      },
      className: i18n.language === 'ja' ? 'bg-day-light-2' : '',
    },
    {
      text: t('language.vi'),
      onClick: async () => {
        await fetchMessageTemplates('vi', 'translation');
        i18n.changeLanguage('vi');
      },
      className: i18n.language === 'vi' ? 'bg-day-light-2' : '',
    },
  ];

  return (
    <div className="relative group">
      <div className="py-1.5 px-2 cursor-pointer hover:bg-day-light-3 rounded-full">
        <i className="fas fa-globe fa-xl text-day-neutral-dark" />
      </div>
      <PageHeaderDropdown
        header={t('language.select')}
        elements={translationOptions}
        className="right-0 hidden group-hover:block min-w-[200px]"
      />
    </div>
  );
};
