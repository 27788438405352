import { useSteps, StepLayoutChildProps, Step } from '@consigli/facade';
import { StructuralType } from '@consigli/types';
import { FC, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PackageForm } from '../components/package-form';
import { RequiredStructuralFiles } from '../components/required-file-types';
import { UserUpload } from '../components/user-upload';
import { WizardLayout } from '../components/wizard-layout';
import { useCreatePackageUtil } from '../hooks/useCreatePackage';

interface StructuralWizardProps {
  userInput: JSX.Element;
}
export const StructuralWizard = ({ userInput }: StructuralWizardProps) => {
  const [formData, setFormData] = useState<FieldValues>({
    iterationName: '',
    inputType: '',
  });
  const { t } = useTranslation();
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showMessagePanel, setShowMessagePanel] = useState(true);
  const { onAnalyze: onProcess } = useCreatePackageUtil();
  const onAnalyze = async () => {
    setIsProcessing(true);
    await onProcess(localFiles, formData, StructuralType.STRUCTURAL);
    setIsProcessing(false);
  };

  const { withStep, StepIndicator, StepOutlet, useStepContext } = useSteps(
    (step: (name: string, component: FC<StepLayoutChildProps>) => Step) => {
      return [
        step(t('package-creation.information'), () => (
          <PackageForm context={useStepContext} setFormData={setFormData} formData={formData}>
            {userInput}
          </PackageForm>
        )),
        step(t('package-creation.upload'), () => (
          <UserUpload
            localFiles={localFiles}
            setLocalFiles={setLocalFiles}
            useStepContext={useStepContext}
            onAnalyze={onAnalyze}
            infoMessage={t('package-creation.info-message')}
          ></UserUpload>
        )),
      ];
    },
  );

  const stepsRenderer = withStep(() => {
    return (
      <>
        <StepIndicator />
        <StepOutlet />
      </>
    );
  });

  const messageHeader = showMessagePanel ? (
    <RequiredStructuralFiles setShowMessagePanel={setShowMessagePanel}></RequiredStructuralFiles>
  ) : (
    <></>
  );

  return (
    <WizardLayout
      stepsRenderer={stepsRenderer}
      isProcessing={isProcessing}
      messageHeader={messageHeader}
      usePackageHook={useCreatePackageUtil}
    />
  );
};
