import { useKeyListener } from '@consigli/hooks';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewerMode, useViewerContext } from '@/contexts/use-viewer-context';
import { useViewer } from '@/hooks/use-viewer';
import { ImageContainer } from '@/molecules/image-viewer/image-container';
import { isImageDocumentType } from '@/util/document-check';

import { DoublePdfViewer } from './double-pdf';
import { isDocumentDoubleViewerCapable } from './fullscreen/finding/container';
import { SinglePdfViewer } from './single-pdf';

export const DocumentPreviewContainer = () => {
  const { t } = useTranslation();
  const { document, mode } = useViewerContext();
  const { openDocumentViewer, close } = useViewer();
  const [previewMode, setPreviewMode] = useState<ViewerMode>();
  const previewRef = useRef<HTMLDivElement>(null);
  const delayedCloseFlag = useRef(false);

  useEffect(() => {
    if (mode === ViewerMode.DocumentPreview && !delayedCloseFlag.current) {
      setTimeout(() => {
        setPreviewMode(ViewerMode.DocumentPreview);
      }, 70);
    } else if (mode === ViewerMode.ReturnToDocumentPreview && !delayedCloseFlag.current) {
      setPreviewMode(ViewerMode.ReturnToDocumentPreview);
    }
    return () => {
      delayedCloseFlag.current = false;
    };
  }, [mode]);

  const delayedClose = useCallback(() => {
    setPreviewMode(ViewerMode.Closed);
    delayedCloseFlag.current = true;
    setTimeout(() => {
      close();
    }, 700);
  }, [close]);

  useKeyListener('Escape', delayedClose);

  const PreviewComponent = useCallback(() => {
    if (!document) {
      return (
        <div className="text-white text-center pt-[50%]">
          {t('document-viewer.no-finding-selected')}
        </div>
      );
    }

    if (isImageDocumentType(document.name)) {
      return <ImageContainer document={document} isPreview={true} />;
    }

    return isDocumentDoubleViewerCapable(document) ? (
      <DoublePdfViewer document={document} isPreview={true} />
    ) : (
      <SinglePdfViewer document={document} isPreview={true} />
    );
  }, [document, t]);

  return (
    <div
      ref={previewRef}
      className={clsx(
        'shadow fixed z-[50] border-l border-[#ebebeb] top-14 right-[-35vw] w-[35vw] pb-14 transition-all duration-700 ease-in-out',
        previewMode == ViewerMode.DocumentPreview && 'translate-x-[-35vw]',
        previewMode == ViewerMode.ReturnToDocumentPreview && 'translate-x-[-35vw] transition-none',
      )}
    >
      <div className="flex flex-col text-white bg-slate-600">
        <div className="flex flex-row py-1">
          <div className="ml-auto">
            <button
              onClick={() => {
                document && openDocumentViewer(document, ViewerMode.DocumentFullscreen);
              }}
              className="fa-solid fa-expand fa-lg h-10 w-10 pr-4"
              title={t('riskassessment.buttons.fullscreen')}
            />
            <button
              onClick={delayedClose}
              className="fa-solid fa-x fa-lg h-10 w-10 pr-4"
              title={t('riskassessment.buttons.close')}
            />
          </div>
        </div>
        <div className="flex justify-center overflow-y-auto h-[calc(100vh-7.5rem)] mb-4">
          <PreviewComponent />
        </div>
      </div>
    </div>
  );
};
