import JSZip from 'jszip';
import { chunk, zip as zipArrays } from './array';
const ZIP_BATCH_SIZE = 100;
export const saveFile = (filename, data) => {
    const a = document.createElement('a');
    a.href = data;
    a.download = filename;
    a.click();
    a.remove();
};
export const MIME_TYPES = {
    json: 'application/json',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
export const saveBlob = (filename, blob) => {
    const url = URL.createObjectURL(blob);
    saveFile(filename, url);
    URL.revokeObjectURL(url);
};
export const saveJson = (filename, json) => saveBlob(filename, new Blob([JSON.stringify(json, null, 4)], { type: 'application/json' }));
export const saveConsigliBlob = async (consigliBlob) => {
    const response = await fetch(consigliBlob.blobUrl);
    const blob = await response.blob();
    saveBlob(consigliBlob.name, blob);
};
const fetchBlob = async (file) => {
    const response = await fetch(file.blobUrl);
    return await response.blob();
};
export const saveConsigliZip = async (consigliBlobFiles, folderName) => {
    const zip = new JSZip();
    for (const batch of chunk(consigliBlobFiles, ZIP_BATCH_SIZE)) {
        const blobs = await Promise.all(batch.map((file) => fetchBlob(file)));
        zipArrays(batch, blobs).forEach(([file, blob]) => {
            zip.file(file.name, blob);
        });
    }
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveBlob(folderName ? folderName + '.zip' : 'files.zip', zipBlob);
};
export const saveFileZip = async (files, folderName) => {
    const zip = new JSZip();
    for (const file of files) {
        const response = await fetch(file.readUrl);
        if (response.ok) {
            const blob = await response.blob();
            zip.file(file.originalFilename, blob);
        }
        else {
            throw new Error(`Failed to fetch file: ${file.originalFilename}`);
        }
    }
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveBlob(folderName ? folderName + '.zip' : 'files.zip', zipBlob);
};
export const saveBlobBase64 = (filename, base64Data, mimeType) => {
    // Convert base64 string to a Blob
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: mimeType });
    saveBlob(filename, blob);
};
