import { LoadingArea } from '@consigli/facade';
import {
  useProjectId,
  useLayoutId,
  useLayoutFiles,
  useLazyGetLayoutOptimizationFilesQuery,
} from '@consigli/hooks';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/components/layouts/workspace-layout/workspace-layout';
import { Documents } from '@/organisms/document-list/documents';
import { convertFileToDocument } from '@/util/document-converters';

export const EngineeringFileList: FC = () => {
  const projectId = useProjectId();
  const layoutId = useLayoutId();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const { files, totalCount, isLoading, isFetching } = useLayoutFiles({
    projectId,
    layoutId,
    page: page,
  });

  const [getAllLayoutFiles] = useLazyGetLayoutOptimizationFilesQuery();

  const downloadAllFiles = useCallback(async () => {
    const layoutFilesResponse = await getAllLayoutFiles({
      projectId,
      layoutId,
      page: 'all',
    }).unwrap();
    return layoutFilesResponse['results'].map((file) => convertFileToDocument(file));
  }, [getAllLayoutFiles, projectId, layoutId]);

  return (
    <>
      <Breadcrumb label={t('optimization-tabs.input')} />
      <WorkspaceLayout>
        {isLoading ? (
          <LoadingArea title={t('document-list.loading')} />
        ) : files.length > 0 ? (
          <Documents
            files={files.map((file) => convertFileToDocument(file))}
            headerTitle={t('document-list.documents')}
            paginatedCount={totalCount}
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            isFetching={isFetching}
            downloadAllFiles={downloadAllFiles}
            packageId={layoutId}
          />
        ) : (
          <div className="flex justify-center items-center w-full h-full text-[24px]">
            {t('document-list.no-documents')}
          </div>
        )}
      </WorkspaceLayout>
    </>
  );
};
