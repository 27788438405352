import {
  useProjectId,
  usePackageId,
  useLazyGetFindingQuery,
  useMessageContext,
} from '@consigli/hooks';
import { Finding } from '@consigli/types';
import { DocumentType } from '@consigli/types';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useViewerContext } from '@/contexts/use-viewer-context';

import { isDocumentDoubleViewerCapable } from './container';
import { UpdateFindingButtons } from './update-finding';

interface FindingsControllerProps {
  itemsOnPage: number;
}

export const FindingsController: FC<FindingsControllerProps> = (props) => {
  const { itemsOnPage } = props;
  const { t } = useTranslation();
  const { document } = useViewerContext();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const [getFinding] = useLazyGetFindingQuery();

  const [finding, setFinding] = useState<Finding>();

  const messageContext = useMessageContext(finding);

  useEffect(() => {
    if (document && document.type === DocumentType.FINDING) {
      getFinding({ projectId, packageId, findingId: document.findingId })
        .unwrap()
        .then((result) => setFinding(result))
        .catch((error) => console.error(error));
    }
  }, [document, getFinding, packageId, projectId]);

  if (document && document.type === DocumentType.FINDING) {
    return (
      document && (
        <div className="w-full h-full text-lg flex flex-col justify-between text-white">
          <div className="flex flex-col justify-between my-3 mx-4">
            <div className="mt-4 ml-4">
              {finding && <UpdateFindingButtons finding={finding} itemsOnPage={itemsOnPage} />}
            </div>
            <div className="py-10 pl-4 max-w-[50vw]">
              <div className="text-2xl">
                {t([
                  `dashboard.conflict-title.${document.typeOfConflict}`,
                  'dashboard.conflict-title.__fallback',
                ])}
              </div>

              <div className="my-3 text-base font-bold">
                {t(document.messageTemplate, {
                  ...messageContext,
                })}
              </div>

              <div className="-mx-4">
                <div className="text-base p-4">
                  <div className="font-bold"> {t(`riskassessment.extract`)}: </div>
                  <div className="italic">
                    {document.content &&
                      document.content.slice(0, 300) +
                        (document.content!.length > 300 ? '...' : '')}
                  </div>
                </div>
                {isDocumentDoubleViewerCapable(document) && (
                  <div className="relative text-base p-4">
                    {document.refFileName}:
                    <div className="italic">
                      {document.refContent.slice(0, 300) +
                        (document.refContent.length > 300 ? '...' : '')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
};
