import { useMyUser, useInvites } from '@consigli/hooks';
import { logout } from '@consigli/oauth';
import { ProjectRole } from '@consigli/types';
import i18n from 'i18next';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { fetchMessageTemplates } from '@/locales/message-templates';
import { Route } from '@/routes';
import { DropdownElement } from '@/util/interfaces';
import { authConfig } from '@/util/oauth/config';

import { PageHeaderDropdown } from './page-header-dropdown';

export const UserMenuButton = () => {
  const { t } = useTranslation();
  const { user } = useMyUser();
  const navigate = useNavigate();

  // UseEffect to fetch message templates for translation
  useEffect(() => {
    if (user) {
      const currentLanguage = i18n.language;
      fetchMessageTemplates(currentLanguage, 'translation');
    }
  }, [user]);

  const { paginatedCount: notificationCount } = useInvites({
    page: 'all',
    recipient: user.email,
  });

  const hasUserManagementAccess: boolean = useMemo(
    () =>
      user.isSuperuser ||
      user.isOrgAdmin ||
      user.projectAccess.some((project) => project.role === ProjectRole.ADMIN),
    [user.isOrgAdmin, user.projectAccess, user.isSuperuser],
  );

  const profileOptions: DropdownElement[] = [
    {
      text: t('navbar.menu.myprofile'),
      icon: (
        <>
          {!user.isSuperuser && notificationCount > 0 && (
            <span className="bg-error-dark text-white rounded-full h-5 w-5 text-sm text-center">
              {notificationCount}
            </span>
          )}
        </>
      ),
      onClick: () => navigate('/profile'),
    },
    ...(hasUserManagementAccess
      ? [
          {
            text: t('navbar.menu.users'),
            onClick: () => navigate(Route.USERS),
          },
        ]
      : []),
    {
      text: t('navbar.menu.terms'),
      onClick: () => window.open(Route.TERMS, '_blank'),
    },
    {
      text: t('navbar.menu.logout'),
      onClick: () => logout(authConfig),
    },
  ];

  return (
    <div className="relative group">
      <div className="py-1.5 px-2 cursor-pointer hover:bg-day-light-3 rounded-full relative">
        <i className="fas fa-user-circle fa-xl text-day-neutral-dark" />
        {!user.isSuperuser && notificationCount > 0 && (
          <span className="absolute top-0 right-0 bg-error-dark text-white rounded-full w-4 h-4 text-xs text-center">
            {notificationCount}
          </span>
        )}
      </div>
      <PageHeaderDropdown
        header={user.firstName + ' ' + user.lastName}
        elements={profileOptions}
        className="right-0 hidden group-hover:block min-w-[200px]"
      />
    </div>
  );
};
