import { Skeleton } from '@consigli/facade';
import { Project } from '@consigli/types';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Route } from '@/routes';

interface ProjectCardProps {
  project: Project;
  icon: string;
}

export const ProjectCard: FC<ProjectCardProps> = ({ project: { id, name, address }, icon }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };
  const defaultAddress = t('project-creation.default-address');

  return (
    <div className="relative group" data-testid={`project-card-${name}`}>
      <Link
        to={`/${Route.PROJECTS}/${id}/${Route.SERVICES}`}
        className="flex flex-col h-full items-center bg-day-light-4 gap-4 rounded-[24px] shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_3px_0_rgba(0,0,0,0.10)] focus:outline-none focus:ring-2 focus:ring-light-grey border-4 border-day-light-4"
      >
        <div className=" relative group overflow-hidden rounded-t-[24px] rounded-b-[24px] w-full">
          {!isLoading && (
            <div className=" absolute top-[calc(0%-80px)] overflow-hidden w-full h-full group-hover:top-0 transition-all duration-500 ease-in-out rounded-tl-[24px] rounded-tr-[24px] group-hover:rounded-b-[24px] bg-day-light-3"></div>
          )}
          <div className="relative flex flex-col justify-evenly text-left w-full mb-4">
            <div className="w-full flex justify-center items-center px-6 p-10 ">
              {isLoading && <Skeleton className="h-48" />}
              <img
                className={`transition-transform h-48 duration-200 ease-in-out group-hover:scale-110 ${isLoading && 'hidden'}`}
                src={icon}
                alt={t('user_administration.project')}
                onLoad={() => setIsLoading(false)}
              />
            </div>
            <div className="my-2">
              <div className="text-[1rem] justify-center text-center text-day-neutral-subtle capitalize">
                {truncateText(address ? address : defaultAddress, 30)}
              </div>
              <div
                className="text-base w-full justify-center text-center px-3 flex-1 font-semibold text-day-neutral-dark whitespace-nowrap overflow-hidden"
                title={name}
              >
                {truncateText(name, 30)}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
