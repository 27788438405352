import { Spinner } from '@consigli/facade';
import { useProjectId, usePackageId, useBlobsById } from '@consigli/hooks';
import { uniqueBy } from '@consigli/utils';
import { clsx } from 'clsx';
import { FC, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle, FaUser } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

import robot from '@/assets/images/robot.webp';
import { ViewerMode } from '@/contexts/use-viewer-context';
import { useViewer } from '@/hooks/use-viewer';
import { convertBlobToDocument } from '@/util/document-converters';
import 'react-tooltip/dist/react-tooltip.css';
import { Message } from '@/util/interfaces';

import { ChatTimeSince } from './chat-time-since';

type ChatMessageProps = {
  from?: 'SYSTEM' | 'USER';
} & (
  | {
      loading?: false;
      message: Message;
    }
  | {
      loading: true;
      message?: Message;
    }
);

export const ChatMessage: FC<ChatMessageProps> = ({ message, loading, from = 'USER' }) => {
  const { t } = useTranslation();
  const tooltipId = useId();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const { blobsById } = useBlobsById(projectId, packageId);
  const { openDocumentViewer } = useViewer();

  const isSystemMessage = from === 'SYSTEM';
  const icon = isSystemMessage ? (
    <img src={robot} alt="consigli silhouette" className="h-10 w-10" />
  ) : (
    <FaUser size={32} className="text-day-neutral-dark" />
  );

  return (
    <div className={clsx('flex w-full mt-2', isSystemMessage ? 'justify-start' : 'justify-end')}>
      <div
        className={clsx(
          'flex gap-x-3 max-w-[52%]',
          isSystemMessage ? 'flex-row' : 'flex-row-reverse',
        )}
      >
        <div className="flex flex-col items-center justify-center flex-shrink-0 h-10 w-10 rounded-full text-3xl">
          {icon}
        </div>
        <div className="max-w-full">
          <div
            className={clsx(
              'p-3',
              isSystemMessage ? 'rounded bg-day-light-3' : 'rounded bg-night-light-4 text-white',
            )}
          >
            {loading ? (
              <Spinner
                size="small"
                className="border-gray-100 border-r-transparent border-t-transparent"
              />
            ) : (
              <p className="break-words">{message.body}</p>
            )}
          </div>
          {loading ? (
            <span className="text-sm">{t('prop-chat.thinking')}</span>
          ) : (
            <div
              className={clsx(
                'pt-1',
                isSystemMessage ? 'flex flex-row justify-between' : 'flex justify-end',
              )}
            >
              <ChatTimeSince date={message.createdAt} />
              {isSystemMessage && message.sources && (
                <span className="flex flex-row justify-end w-full">
                  <FaInfoCircle
                    className="cursor-pointer text-day-neutral-dark"
                    data-tooltip-id={tooltipId}
                  />
                  <Tooltip className="max-w-[500px] break-normal" id={tooltipId} clickable>
                    <div>{t('prop-chat.source-list')}</div>
                    {uniqueBy(message.sources, (source) => source.blobId).map((source) => {
                      const blob = blobsById[source.blobId];
                      if (blob) {
                        return (
                          <button
                            key={blob.id}
                            onClick={() =>
                              openDocumentViewer(
                                convertBlobToDocument(blob),
                                ViewerMode.DocumentFullscreen,
                              )
                            }
                            className="block font-bold"
                          >
                            {blob.name}
                          </button>
                        );
                      }
                    })}
                  </Tooltip>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
