import { UseStepContext } from '@consigli/facade';
import { useServiceName } from '@consigli/hooks';
import { PipelineName, ServiceId } from '@consigli/types';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { SupportedFileFormats } from '@/atoms/supported-file-formats';
import { ServiceIdIndexValue } from '@/routes';

import { UserUpload } from '../components/user-upload';
import { useCreateInsightPackage } from '../hooks/useCreateInsightPackage';

interface InsightUploadProps {
  localFiles: File[];
  setLocalFiles: Dispatch<SetStateAction<File[]>>;
  formData: FieldValues;
  useStepContext: UseStepContext;
  setIsProcessing: Dispatch<SetStateAction<boolean>>;
}

export const InsightUpload = ({
  localFiles,
  setLocalFiles,
  formData,
  useStepContext,
  setIsProcessing,
}: InsightUploadProps) => {
  const { t } = useTranslation();
  const { createDocumentPackage, uploadFiles, startAnalysis } = useCreateInsightPackage();
  const serviceName = useServiceName();
  const serviceId = ServiceIdIndexValue[serviceName] as ServiceId;
  const onAnalyze = useCallback(async () => {
    // step 1 , create package
    try {
      setIsProcessing(true);
      const createdPackageId = await createDocumentPackage(
        serviceId,
        formData.iterationName,
        formData.language,
        formData.needClassification,
      );
      if (createdPackageId === undefined) {
        return;
      }

      // step 2, upload files from local state of files, , then initiate analysis phase
      await uploadFiles(localFiles, createdPackageId);

      // step 3, start pipeline
      await startAnalysis(createdPackageId, PipelineName.DEFAULT);
      toast.success(t('package-creation.success-analyzed'));
    } catch (error) {
      toast.error(t(`package-creation.failed-analyzed`));
    } finally {
      setIsProcessing(false);
    }
  }, [
    createDocumentPackage,
    formData,
    localFiles,
    startAnalysis,
    t,
    serviceId,
    uploadFiles,
    setIsProcessing,
  ]);

  return (
    <UserUpload
      localFiles={localFiles}
      setLocalFiles={setLocalFiles}
      useStepContext={useStepContext}
      onAnalyze={onAnalyze}
      infoMessage={<SupportedFileFormats />}
    ></UserUpload>
  );
};
