import { Button, LoadingArea, Position, Search } from '@consigli/facade';
import { useDebouncedSearch, useMyUser, useProjects } from '@consigli/hooks';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { CenteredLayout } from '@/layouts/centered-content';
import { FluidLayout } from '@/layouts/fluid-content';
import { ProjectCards } from '@/molecules/project-cards';
import { Route } from '@/routes';

export const ProjectListPage: FC = () => {
  const navigate = useNavigate();
  const { user } = useMyUser();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebouncedSearch(searchText, 500);

  const { projects: projects, isFetching } = useProjects({
    page: 'all',
    search: debouncedSearchText,
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <FluidLayout>
      <div className="flex border-b">
        <div className="flex w-full flex-col sm:flex-row sm:justify-end items-center p-3">
          <Search
            text={searchText}
            onChange={handleSearch}
            placeholder={t('project-list.search-projects')}
          />
          {user.organizationId && (
            <Button
              rounded
              primary
              className="mt-4 sm:mt-0 mx-4 px-4 text-base font-semibold"
              onClick={() => {
                navigate(`/${Route.NEW_PROJECT}`);
              }}
              icon={HiOutlinePlusSm}
              iconSize={23}
              iconPosition={Position.RIGHT}
              iconColor={'white'}
            >
              {t('project-creation.new-project')}
            </Button>
          )}
        </div>
      </div>
      <CenteredLayout>
        <div
          data-testid="welcome-heading"
          className="text-[2rem] font-semibold capitalize text-center text-day-neutral-dark pt-4"
        >
          {t('project-list.welcome')} {user.firstName + ' ' + user.lastName}!
        </div>
        <p
          data-testid="project-overview-text"
          className="text-base p-4 text-center text-day-neutral-dark pt-4 pb-8"
        >
          {t('project-list.your-projects')}
        </p>
        {isFetching ? (
          <div className="flex justify-center items-center">
            <LoadingArea title={t('project-list.loadingtitle')} className="mx-auto mt-[25vh]" />
          </div>
        ) : (
          <div className="px-14 pb-8">
            <ProjectCards projects={projects} searchText={searchText} />
          </div>
        )}
      </CenteredLayout>
    </FluidLayout>
  );
};
