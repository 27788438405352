import { renderLoader } from '@consigli/utils';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import {
  HighlightArea,
  RenderHighlightsProps,
  Trigger,
  highlightPlugin,
} from '@react-pdf-viewer/highlight';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { getDocument } from 'pdfjs-dist';
import { useCallback, useMemo, useRef, type FC } from 'react';

import { usePDFToolbar } from './custom-toolbar';
import { PDFViewerError } from './pdf-viewer-error';
import { regexpIgnoreWhitespaces } from './search-regexp';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

// Cannot execute this function in this file, because running functions from pdfjs conflict with the Worker and causes an error in tests
// Therefore we delegate this task to parents
export const getPDFSize = async (fileUrl: string) => {
  const pdfDocument = await getDocument(fileUrl).promise;
  const page = await pdfDocument.getPage(1);
  const viewport = page.getViewport({ scale: 1 });
  return { pdfWidth: viewport.width, pdfHeight: viewport.height };
};

type PDFViewerProps = {
  /**
   * Initial page number to jump to upon opening the PDF.
   * First page is index 0
   *
   * If not specified, the PDF will open on the first page.
   */
  initialPageNumber?: number;
  /**
   * Dimensions of the pdf, this is used to calculate the highlight area
   */
  pdfSize?: { pdfWidth: number; pdfHeight: number };
  /**
   * Coordinates for the finding, which will be highlighted, if this is not set, it will fallback to searchTerm
   */
  findingCoordinates?: { x0: number; x1: number; y0: number; y1: number; pageIndex: number };
  /**
   * Search term to highlight
   */
  searchTerm?: string;
  /**
   * Fallback page number if search fails to match in text (0-indexed)
   */
  searchFallbackPage?: number;
  /**
   * Path to the PDF document to render
   */
  fileUrl: string;
  /**
   * To remove toolbar when in preview mode
   */
  isPreview: boolean;
  /**
   * To get the document name
   */
  documentName: string;
  /**
   * Unique ID for the PDF viewer. Needed to fix scroll issue for the second viewer.
   */
  id?: number;
};

export const PDFViewer: FC<PDFViewerProps> = ({
  initialPageNumber = 1,
  pdfSize,
  findingCoordinates,
  searchTerm,
  searchFallbackPage = 0,
  fileUrl,
  isPreview = true,
  documentName,
  id = 1,
}) => {
  // Initialize all the React PDF viewer plugins
  const keyword = useMemo(
    () => (searchTerm ? regexpIgnoreWhitespaces(searchTerm.replace('|', '')) : undefined),
    [searchTerm],
  );

  const highlightArea: HighlightArea | undefined = useMemo(() => {
    if (!findingCoordinates || !pdfSize || pdfSize.pdfHeight === 0 || pdfSize.pdfWidth === 0) {
      return undefined;
    }

    const { x0, x1, y0, y1, pageIndex } = findingCoordinates;
    const { pdfHeight, pdfWidth } = pdfSize;

    const height = (Math.abs(y0 - y1) * 100) / pdfHeight;
    const width = (Math.abs(x0 - x1) * 100) / pdfWidth;
    const top = (Math.min(y0, y1) * 100) / pdfHeight;
    const left = (Math.min(x0, x1) * 100) / pdfWidth;

    return { height, left, top, width, pageIndex };
  }, [findingCoordinates, pdfSize]);

  const { fullscreenToolbar, previewToolbar, navigation, zoom, download, search } =
    usePDFToolbar(documentName);

  const renderHighlights = (props: RenderHighlightsProps) =>
    highlightArea !== undefined && highlightArea.pageIndex === props.pageIndex ? (
      <div
        style={{
          background: 'yellow',
          opacity: 0.4,
          ...props.getCssProperties(highlightArea, props.rotation),
        }}
      />
    ) : (
      <></>
    );

  const highlight = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  const toolbar = toolbarPlugin({
    searchPlugin: {
      enableShortcuts: highlightArea == null,
      keyword,
    },
  });

  /**
   * We highlight the initial search terms once. The most reliable way to do this is to wait for a page change event
   * @note that we need to unmount the component in order to re-trigger the search
   */
  const initialized = useRef({ findingScrolled: false, searchScrolled: false });

  const PADDING = 25;

  const scrollToHighlightArea = (
    pageContainer: Element,
    page: Element,
    findingCoordinates: { pageIndex: number },
    highlightArea: HighlightArea,
  ) => {
    const scrollHeight = page.getBoundingClientRect().height;
    const offsetPageIndex = findingCoordinates.pageIndex * scrollHeight;
    const offsetHighlightOnPage = (highlightArea.top / 100) * scrollHeight;

    requestAnimationFrame(() =>
      pageContainer.scrollTo(0, offsetPageIndex + offsetHighlightOnPage - PADDING),
    );
  };

  const clearHighlightsAndSetTargetPages = useCallback(() => {
    toolbar.searchPluginInstance.clearHighlights();
    if (searchFallbackPage !== 0) {
      toolbar.searchPluginInstance.setTargetPages(
        (targetPage) => targetPage.pageIndex >= searchFallbackPage - 2,
      );
    } else {
      toolbar.searchPluginInstance.setTargetPages(() => true);
    }
  }, [searchFallbackPage, toolbar.searchPluginInstance]);

  const handlePageChange = useCallback(async () => {
    if (initialized.current.findingScrolled || initialized.current.searchScrolled || !keyword) {
      return;
    }

    if (findingCoordinates && highlightArea) {
      initialized.current.findingScrolled = true;
      clearHighlightsAndSetTargetPages();

      const pageContainer = document.querySelector(`[data-key="${id}"] .rpv-core__inner-pages`);
      const page = document.querySelector(`[data-key="${id}"] .rpv-core__inner-page`);

      if (pageContainer && page) {
        scrollToHighlightArea(pageContainer, page, findingCoordinates, highlightArea);
      } else {
        requestAnimationFrame(() => navigation.jumpToPage(findingCoordinates.pageIndex));
      }
      return;
    }

    initialized.current.searchScrolled = true;
    clearHighlightsAndSetTargetPages();

    try {
      const match = await toolbar.searchPluginInstance.highlight(keyword);
      if (match.length == 0) {
        navigation.jumpToPage(searchFallbackPage);
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    findingCoordinates,
    keyword,
    highlightArea,
    clearHighlightsAndSetTargetPages,
    id,
    navigation,
    toolbar.searchPluginInstance,
    searchFallbackPage,
  ]);

  const onInitialLoad = useCallback(() => {
    setTimeout(() => {
      handlePageChange();
      zoom.zoomTo(SpecialZoomLevel.PageWidth);
    }, 2000);
  }, [handlePageChange, zoom]);

  return (
    <>
      {isPreview ? <>{previewToolbar}</> : <>{fullscreenToolbar}</>}
      <Worker workerUrl="/libs/pdfjs-dist@3.4.120.min.js">
        <Viewer
          key={id}
          fileUrl={fileUrl}
          initialPage={initialPageNumber - 1}
          renderError={(error) => <PDFViewerError error={error} />}
          onPageChange={handlePageChange}
          onDocumentLoad={onInitialLoad}
          plugins={[toolbar, highlight, navigation, zoom, download, search]}
          renderLoader={renderLoader}
        />
      </Worker>
    </>
  );
};
