import { Button } from '@consigli/facade';
import { useLazyGetFileReadUrlQuery } from '@consigli/hooks';
import { Document, DocumentType } from '@consigli/types';
import { saveBlob, saveConsigliBlob } from '@consigli/utils';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineFileDownload } from 'react-icons/md';

export const DownloadFileAction: FC<{ document: Document }> = ({ document }) => {
  const { t } = useTranslation();
  const [getReadUrl] = useLazyGetFileReadUrlQuery();

  const performDownload = useCallback(
    async (file: Document) => {
      // Documents are Blob type
      if (file.type === DocumentType.BLOB) {
        saveConsigliBlob({ name: file.name, blobUrl: file.blobUrl });
      }
      if (file.type === DocumentType.FILE) {
        // Documents are File type
        const { readUrl } = await getReadUrl({
          projectId: file.projectId,
          fileId: file.id,
        }).unwrap();
        const response = await fetch(readUrl);
        const data = await response.blob();
        saveBlob(file.name, data);
      }
    },
    [getReadUrl],
  );

  return (
    <Button
      secondary
      rounded
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        performDownload(document);
      }}
      title={t('document-list.download')}
      icon={MdOutlineFileDownload}
      iconSize={23}
    />
  );
};
