import { LoadingArea } from '@consigli/facade';
import { useProjectId, useServiceName } from '@consigli/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nav } from '@/layouts/nav';

import { ComparisonSteps } from './compare/comparison-steps';
import { CompareProvider } from './compare/use-compare-packages';

export const ComparisonWizard = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const serviceName = useServiceName();

  const [isComparing, setIsComparing] = useState(false);

  return (
    <div className="w-full flex flex-col min-h-[calc(100vh-theme(space.14))] bg-day-light-4 dark:bg-night-light-4">
      <Nav projectId={projectId} />
      <div className="flex flex-col flex-1 items-center px-4 py-12 w-full sm:w-[31rem] mx-auto">
        <div className="mb-10 font-semibold text-2xl text-day-neutral-dark dark:text-night-neutral-dark">
          <>{t(`package-creation.new.${serviceName}`)}</>
        </div>
        <>
          {isComparing ? (
            <LoadingArea className="mt-20" title={t('document-upload.uploading')} />
          ) : (
            <CompareProvider>
              <ComparisonSteps setIsComparing={setIsComparing}></ComparisonSteps>
            </CompareProvider>
          )}
        </>
      </div>
    </div>
  );
};
