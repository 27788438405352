import { CustomSelect, OptionType } from '@consigli/facade';
import { Language } from '@consigli/types';
import { FieldValues, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';

export const SelectLanguage = () => {
  const { t } = useTranslation();

  const { control } = useFormContext<FieldValues>();
  const { field: languageField, fieldState: languageFieldState } = useController({
    name: 'language',
    control,
    rules: {
      validate: (value) => !!value || `${t('createproject.invalid-project-language-empty')}*`,
    },
  });
  const languageOptions: OptionType[] = [
    {
      label: t('language.nb'),
      value: Language.NB,
    },
    {
      label: t('language.nn'),
      value: Language.NN,
    },
    {
      label: t('language.en'),
      value: Language.EN,
    },
    {
      label: t('language.ja'),
      value: Language.JA,
    },
    {
      label: t('language.vi'),
      value: Language.VI,
    },
  ];
  return (
    <span className="mb-8 mt-4">
      <CustomSelect
        onBlur={() => {}}
        onChange={(newValue: SingleValue<OptionType> | MultiValue<OptionType>) => {
          if (newValue && 'value' in newValue) {
            languageField.onChange(newValue.value);
          }
        }}
        options={languageOptions}
        placeholder={t('language.select')}
        value={languageOptions.find((option: OptionType) => option.value === languageField.value)}
      />
      {languageFieldState.invalid && (
        <div className="text-red-700 text-sm">{languageFieldState.error?.message as string}</div>
      )}
    </span>
  );
};
