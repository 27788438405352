import { Step, useSteps, StepLayoutChildProps } from '@consigli/facade';
import { FC, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InsightUpload } from './insight-upload';
import { PackageForm } from '../components/package-form';
import { WizardLayout } from '../components/wizard-layout';
import { useCreateInsightPackage } from '../hooks/useCreateInsightPackage';

interface InsightWizardProps {
  userInput: JSX.Element;
}

export const InsightWizard = ({ userInput }: InsightWizardProps) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FieldValues>({
    iterationName: '',
    language: '',
    needClassification: false,
  });
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const { withStep, StepIndicator, StepOutlet, useStepContext } = useSteps(
    (step: (name: string, component: FC<StepLayoutChildProps>) => Step) => {
      return [
        step(t('package-creation.details'), () => (
          <PackageForm context={useStepContext} setFormData={setFormData} formData={formData}>
            {userInput}
          </PackageForm>
        )),
        step(t('package-creation.upload'), () => (
          <InsightUpload
            localFiles={localFiles}
            setLocalFiles={setLocalFiles}
            formData={formData}
            useStepContext={useStepContext}
            setIsProcessing={setIsProcessing}
          ></InsightUpload>
        )),
      ];
    },
  );

  const stepsRenderer = withStep(() => {
    return (
      <>
        <StepIndicator />
        <StepOutlet />
      </>
    );
  });

  return (
    <WizardLayout
      stepsRenderer={stepsRenderer}
      isProcessing={isProcessing}
      usePackageHook={useCreateInsightPackage}
    />
  );
};
