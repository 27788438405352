import { coreApiBaseUrl } from '@consigli/hooks';
import { getSessionFromStorage } from '@consigli/oauth';
import axios, { AxiosResponse } from 'axios';
import i18n from 'i18next';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  const token = getSessionFromStorage();
  if (!token) {
    return Promise.reject(new Error('No token'));
  }
  config.headers['Authorization'] = `Bearer ${token.idToken.token}`;
  return config;
});

type TranslationData = {
  [key: string]: string;
};

const languageCodeToLanguage = (languageCode: string): string => {
  switch (languageCode) {
    case 'en-US':
    case 'en-GB':
    case 'en':
      return 'en';
    case 'vi':
      return 'vi';
    case 'ja':
      return 'ja';
    case 'no-NN':
    case 'nn':
      return 'nn';
    case 'no-NB':
    default:
      return 'nb';
  }
};

export const fetchMessageTemplates = async (lng: string, ns: string) => {
  const language = languageCodeToLanguage(lng);
  try {
    const response: AxiosResponse<TranslationData> = await axiosInstance.get(
      `${coreApiBaseUrl}/locales/${language}`,
    );

    const translations: TranslationData = response.data;
    i18n.addResources(lng, ns, translations);
  } catch (error) {
    console.error('Error loading findings', error);
  }
};
