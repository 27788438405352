// import { StepLayoutChildProps, Step, useSteps, UseStepContext } from '@consigli/facade';
import { useMyUser, useCreateProjectMutation } from '@consigli/hooks';
import { Project } from '@consigli/types';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { CenteredLayout } from '@/layouts/centered-content';
import { FluidLayout } from '@/layouts/fluid-content';
import { Nav } from '@/layouts/nav';
import { Route } from '@/routes';
import { ProjectInputs } from '@/util/types';

import { ProjectDetails } from './project-details';

// type TypeOfStepIndicatorRef = {
//   getStepIndicatorMethods: () => {
//     next: () => void;
//     hasNext: boolean;
//     previous: () => void;
//     hasPrevious: boolean;
//     step: number;
//   };
// };

export const ProjectCreation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<ProjectInputs>();
  const { user } = useMyUser();
  const [createProject, { isLoading }] = useCreateProjectMutation();

  // const stepIndicatorRef = useRef<TypeOfStepIndicatorRef | null>(null);

  const createNewProject = useCallback(async () => {
    const {
      name,
      address,
      country,
      projectType,
      specifications,
      constructionTypes,
      invoiceTo,
      orgNumber,
      billingAddress,
      billingInformation,
    } = getValues();
    if (!user.organizationId) {
      toast.error(`${t('project-creation.not-belong-to-organisation')}`);
      throw new Error('Cannot create new project without belonging to an organization!');
    }
    try {
      if (name && projectType) {
        const project: Project = await createProject({
          organizationId: user.organizationId,
          name,
          address,
          country,
          projectType,
          specifications,
          constructionTypes,
          invoiceTo,
          billingInformation: billingInformation ? billingInformation : null,
          orgNumber: orgNumber ? orgNumber : null,
          billingAddress: billingAddress ? billingAddress : null,
        }).unwrap();
        toast.success(`${t('project-creation.success', { projectName: project.name })}`);
        navigate(`/${Route.PROJECTS}/${project.id}/${Route.SERVICES}`);
      }
    } catch {
      toast.error(`${t('project-creation.failure')}`);
    }
  }, [getValues, user.organizationId, createProject, t, navigate]);
  const onSubmit: SubmitHandler<ProjectInputs> = useCallback(
    async () => {
      // if (stepIndicatorRef && stepIndicatorRef.current) {
      //   const { hasNext, next } = stepIndicatorRef?.current?.getStepIndicatorMethods();
      //   if (hasNext) {
      //     next();
      //   } else {
      await createNewProject();
      navigate(`/${Route.PROJECTS}`);
    }, // remove this comma when un commenting the rest!
    // }
    //},
    [createNewProject, navigate],
  );
  // const { withStep, StepIndicator, StepOutlet, useStepContext } = useSteps(
  //   (step: (name: string, component: FC<StepLayoutChildProps>) => Step) => {
  //     return
  //       step(t('project-creation.project-details'), () => (
  //         <ProjectDetails
  //           ref={stepIndicatorRef}
  //           register={register}
  //           errors={errors}
  //           control={control}
  //         />
  //       )),
  //       step(t('project-creation.billing-information'), () => (
  //         <BillingInfo
  //           ref={stepIndicatorRef}
  //           register={register}
  //           errors={errors}
  //           control={control}
  //           getValues={getValues}
  //           context={useStepContext}
  //         />
  //       )),
  //     ;
  //   },
  // );

  const onBackClick = useCallback(() => {
    // if (stepIndicatorRef && stepIndicatorRef.current) {
    //   const { hasPrevious, previous } = stepIndicatorRef?.current?.getStepIndicatorMethods();
    //   if (hasPrevious) {
    //     previous();
    //   } else {
    navigate(`/${Route.PROJECTS}`);
  }, [navigate]);
  return (
    <FluidLayout>
      <Nav handleClick={onBackClick}></Nav>
      <Breadcrumb label={t('navbar.breadcrumbs.home')} to={`/${Route.PROJECTS}`} />
      <CenteredLayout>
        <h1 className="text-heading font-black mt-5 text-center text-day-neutral-subtle">
          {t('project-creation.project-details')}
        </h1>
        <form
          className="flex flex-col flex-1 items-center px-4 py-12 w-full sm:w-[30rem] mx-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* <StepIndicator />
            <StepOutlet /> */}
          <ProjectDetails
            register={register}
            errors={errors}
            control={control}
            getValues={getValues}
            isLoading={isLoading}
            // context={useStepContext}
          />
        </form>
      </CenteredLayout>
    </FluidLayout>
  );
};
