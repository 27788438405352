import { MessagePanel, MessagePanelTypes } from '@consigli/facade';
import { useServiceName } from '@consigli/hooks';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface RequiredFilesProps {
  setShowMessagePanel: Dispatch<SetStateAction<boolean>>;
}

export const RequiredStructuralFiles = ({ setShowMessagePanel }: RequiredFilesProps) => {
  const { t } = useTranslation();
  const serviceName = useServiceName();
  return (
    <MessagePanel type={MessagePanelTypes.INFO} onClose={() => setShowMessagePanel(false)}>
      <p className="font-bold">{t('package-creation.filetype-info.necessary-files')}</p>
      <p>{t(`package-creation.filetype-info.${serviceName}.first-filetype`)}</p>
    </MessagePanel>
  );
};

export const RequiredOptimizationFiles = ({ setShowMessagePanel }: RequiredFilesProps) => {
  const { t } = useTranslation();
  const serviceName = useServiceName();
  return (
    <MessagePanel type={MessagePanelTypes.INFO} onClose={() => setShowMessagePanel(false)}>
      <p className="font-bold">{t('package-creation.filetype-info.necessary-files')}</p>
      <p>{t(`package-creation.filetype-info.${serviceName}.first-filetype`)}</p>
      <p>{t(`package-creation.filetype-info.${serviceName}.second-filetype`)}</p>
    </MessagePanel>
  );
};
