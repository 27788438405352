import { Blob, FoldersDocumentType } from '@consigli/types';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { TreeNode } from '@/pages/folders/util/document-list-to-tree';

export const FileTreeContext = createContext<{
  activeFolderFiles: Blob[];
  setActiveFolderFiles: Dispatch<SetStateAction<Blob[]>>;
  tree: {
    children: Map<string, TreeNode>;
  };
  setTree: Dispatch<
    SetStateAction<{
      children: Map<string, TreeNode>;
    }>
  >;
  activeFolderId: string;
  setActiveFolderId: Dispatch<SetStateAction<string>>;
  summaryCount: Record<string, number> | undefined;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  selectedDocumentTypes: FoldersDocumentType[];
  setSelectedDocumentTypes: Dispatch<SetStateAction<FoldersDocumentType[]>>;
  searchByFilename: boolean;
  setSearchByFilename: Dispatch<SetStateAction<boolean>>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
} | null>(null);

export const useFileTreeContext = () => {
  const ctx = useContext(FileTreeContext);
  if (ctx === null) {
    throw new Error(`Called useFileTreeContext without Provider in tree`);
  }
  return ctx;
};
